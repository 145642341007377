import "./Login.scss";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  TextField,
  Link,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { baseUrl } from "utils/baseUrl";
import CryptoJS from "crypto-js";

interface LoginProps {
  saveUser: () => void;
}

interface LoginValues {
  email: string;
  password: string;
}

export default function Login({ saveUser }: LoginProps) {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [errMassage, setErrMassage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  const notify = (msg: string, type: "success" | "error") => {
    // toast[type](msg);
    if (type === "success") {
      toast.success(msg);
    } else {
      toast.error(msg);
    }
  };

  // const handleLogout = () => {
  //   localStorage.removeItem("userToken");
  //   localStorage.removeItem("user");
  //   navigate("/login");
  // };
  const handleLogout = useCallback(() => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("user");
    navigate("/login");
  }, [navigate]);

  useEffect(() => {
    // setTimeout(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    localStorage.getItem("userToken");
    if (user && !user.isActive) {
      // User is not active, perform logout
      handleLogout();
    }
    // }, 500);
  }, [navigate, handleLogout]);

  async function handleLogin(values: LoginValues) {
    setisLoading(true);
    setErrMassage(null);

    try {
      let response = await axios.post(`${baseUrl}/auth/signin`, values);

      let { data } = response;

      if (data.message === "success") {
        const encryptedEmail = CryptoJS.AES.encrypt(
          data.data.user.email,
          "secret key 1oknvgudcxas23"
        ).toString();

        const encryptedId = CryptoJS.AES.encrypt(
          data.data.user.id.toString(),
          "secret key 1oknvgudcxas23"
        ).toString();

        localStorage.setItem("encryptedId", encryptedId);
        localStorage.setItem("encryptedEmail", encryptedEmail);

        // dispatch(
        //   setUser({ email: data.data.user.email, id: data.data.user.id })
        // );
        // if (response.data.data.user) {
        //   dispatch(setUser(response.data.data.user));
        // } else {
        //   console.error("User data is missing from the response");
        // }

        if (!data.data.user.isActive) {
          toast.error(
            "Your account is not active. Please contact administration."
          );
          handleLogout();
        } else {
          localStorage.setItem("userToken", data.token);
          localStorage.setItem("user", JSON.stringify(data.data.user));
          setisLoading(false);
          saveUser();
          toast.success("Logged in successfully");
          navigate("/dashboard");
        }
      } else {
        toast.error(data.message || "Failed to login");
      }
    } catch (err: any) {
      // console.log('================err====================');
      // console.log(err.response.data);
      // console.log('====================================');
      setisLoading(false);
      // const errorMessage = err.response.data.error || "An error occurred";
      const errorMessage =
        err.response?.data?.message || "An error occurred during login";
      notify(errorMessage, "error");
      setErrMassage(errorMessage);
    } finally {
      setisLoading(false);
    }
  }

  let mySchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is Required"),
    password: Yup.string().required("Password is Required"),
    // .matches(/^[A-Z][a-z0-9]{3,8}$/, "invalid password")
  });

  let formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: mySchema,
    onSubmit: (values) => handleLogin(values),
  });

  return (
    <Box className="login-page">
      <Box className="formParent">
        <form onSubmit={formik.handleSubmit}>
          <h3>Login</h3>
          {errMassage ? (
            <div className="alert alert-danger">{errMassage}</div>
          ) : (
            ""
          )}
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            FormHelperTextProps={{ className: "textfield-error-text" }}
          />

          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            margin="normal"
            // type="password"
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            FormHelperTextProps={{ className: "textfield-error-text" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box
            sx={{
              color: "while",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "20px",
            }}
          >
            <Box>
              <Checkbox id="user-check" />
              <label htmlFor="user-check">Remember me</label>
            </Box>

            <Link href="#/forgotPassword">Forgot Your Password ...?</Link>
          </Box>

          {/* <br /> */}
          <Button
            type="submit"
            variant="contained"
            // color="primary"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            {isLoading ? <CircularProgress size={24} /> : "Login"}
          </Button>
        </form>
      </Box>
    </Box>
  );
}
